<template>
    <v-container style="background:#f2f2f2; min-height:100vh;">
        <v-row class="py-0" style="background:white;">
            <v-col cols="3">
                <v-btn @click="atras" text style="text-transform: capitalize;">
                    <v-icon> mdi-chevron-left</v-icon> Atrás
                </v-btn>
            </v-col>
            <v-col cols="6" class="py-4" style="text-align:center; font-size:18px;">
                Tu Canasta
            </v-col>
        </v-row>
        <template v-for="(carrito,index) in carritos">
            <v-card flat :key="index">
                <div style="text-align:left; background-color:#f2f2f2!important;">
                    <v-row class="ma-5 pa-1" style="background:white; border-radius:4px;">
                        <v-col class="my-1" cols="4">
                            <v-img style="height:70px!important;" :src="foto(carrito.producto)"></v-img>
                        </v-col>
                        <v-col cols="8" class="py-2">
                            {{producto(carrito.producto)}}
                            <br><strong>{{carrito.total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                            <div style="float: right; transform: scale(.9);" class="pt-2">
                                <a style="color:#011e7a; background:#e8e8e8; padding:5px 10px; border-radius:5px;">  <strong style="cursor:pointer;" class="px-3" @click="menos(carrito, index)">- </strong> {{carrito.cantidad}} <strong class="px-3" style="cursor:pointer;" @click="mas(carrito, index)"> + </strong> </a> 
                                <v-btn style="margin-top:-4px;" small icon @click="removeItem(index)"><v-icon>   mdi-delete </v-icon></v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </template>
        
        <div style="filter:opacity(0.21);" v-if="carritos.length==0 && sucursal.status=='activo'">
            <v-img max-width="50vw" style="margin:25vw 25vw 30px 25vw;" src="https://tacoselguero.mx/wp-content/uploads/2021/05/shopping-cart-empty-side-view-1.png"></v-img>
            <strong style="display: table; font-size: 25px; margin: auto;">Carrito Vacio</strong>
        </div>

        <!-- cerrado -->
        <div style="filter:opacity(0.21);" v-if="sucursal.status=='inactivo'">
            <v-img max-width="50vw" style="margin:25vw 25vw 30px 25vw;" src="https://tacoselguero.mx/wp-content/uploads/2021/05/close.png"></v-img>
            <strong style="display: table; font-size: 25px; margin: auto;">La sucursal ya cerró</strong>
        </div>
        
        <v-card v-if="carritos.length>0 && sucursal.status=='activo'" class="perro" :to="{ path: '/pagar'}" style="z-index: 9; position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:90%; border-radius: 30px!important; background: #001e7a;">
            <v-row class="text-center px-4 py-3" style="color:white;">
                <v-col style="cursor:pointer;" cols="5">
                    <a style="color:white; padding:5px 10px; border-radius:5px;">  Ir a pagar </a>
                </v-col>
                <v-col style="cursor:pointer;" cols="7">
                    Subtotal: {{total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                </v-col>
            </v-row>
        </v-card>


        
         <v-card v-else class="perro" style="z-index: 9; position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:90%; border-radius: 30px!important; background: #001e7a75;">
            <v-row class="text-center px-4 py-3" style="color:white;">
                <v-col style="cursor:pointer;" cols="5">
                    <a style="color:white; padding:5px 10px; border-radius:5px;">  Ir a pagar </a>
                </v-col>
                <v-col style="cursor:pointer;" cols="7">
                    Subtotal: {{total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
export default {
    methods:{
        atras(){
            window.history.back();
        },
        foto(id){
            return this.$store.state.producto.productos.filter(producto=>producto.id == id).map(producto => producto.foto)[0]
        },
        producto(id){
            return this.$store.state.producto.productos.filter(producto=>producto.id == id).map(producto => producto.nombre)[0]
        },
        removeItem(index) {
            this.$store.dispatch('carrito/removeItem', index);
        },
        menos(carrito, index){
            if(carrito.cantidad>=2){
                carrito.total = carrito.total/carrito.cantidad
                carrito.cantidad = carrito.cantidad - 1
                carrito.total = carrito.total*carrito.cantidad
                this.$store.dispatch('carrito/editItem', carrito, index);
            }else{
                this.removeItem(index)
            }
        },
        mas(carrito, index){
            carrito.total = carrito.total/carrito.cantidad
            carrito.cantidad = carrito.cantidad + 1
            carrito.total = carrito.total*carrito.cantidad
            this.$store.dispatch('carrito/editItem', carrito, index);
        }
    },
    created(){
        this.$store.dispatch('producto/getProductos') 
        this.$store.dispatch('sucursal/getSucursales')
    },
    computed:{
        carritos() {
            return this.$store.state.carrito.carritos
        },
        total(){
            var perro = this.$store.state.carrito.carritos
            if(perro!=undefined){
                var sum = 0
                for(var i=0; i<perro.length; i++){
                        sum = sum + perro[i].total
                }
                return sum
            }else{
                return 0
            }
        },
        sucursal(){
            return this.$store.state.sucursal.sucursales.filter(sucursal=>sucursal.id == localStorage.getItem("sucursal"))[0]
        }
    },
    mounted(){
      Echo.channel('test')
          .listen('Test', (e) => {
            this.$store.dispatch('sucursal/getSucursales')
          })
    },
}
</script>

<style>

</style>